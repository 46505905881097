import logoAHI from "../../logos/ahi.png"
import logoPVA from "../../logos/pva.png"

const logos = {
  "ahi-annual": logoAHI,
  "pva": logoPVA,
  "pva-vhma": logoPVA,
}

const defaultLogoURL = 'https://recurly-client-production.s3.amazonaws.com/logos/act-logo.png';

const getLogo = (planCode) => logos[planCode] || defaultLogoURL;

const logoFactory = {
    getLogo
};

export default logoFactory;