import { useState } from 'react';

import CardHeader from './CardHeader';

import currencyFormatter from './model/formatters/currencyFormatter';

const OrderSummaryPaidSubscription = ({
  step,
  name = '',
  amount = 0,
  isSupportQuantity = false,
  onPlanQuantityChanged = () => {}
}) => {
  const [planQuantity, setPlanQuantity] = useState(1);

  const onChangePlanQuantity = (value) => {
    value = value.replace(/\D+/g, "");
    setPlanQuantity(value);

    value ? onPlanQuantityChanged(value) : onPlanQuantityChanged(0);
  }

  const onBlurPlanQuantity = (value) => {
    if (!value || parseInt(value < 1)) {
      value = 1;
      setPlanQuantity(value);
      onPlanQuantityChanged(value);
    }    
  }
  
  return (
    <div id='order-summary' className='Card' data-component='card'>
      <CardHeader
        cardTitle={step.stepTitle}
        displayStep={false}
      />
      <div className='Card-row Card-row--soft'>
        <div className='Card-col Flex-center Plan-col'>
          {name}
          <span className='text-capitalize'>
          </span>
        </div>
        {isSupportQuantity && (
          <div className="Card-col Card-col--soft Qty-col Flex-center">
            <div className={"qty qty-editable" + " " + (isSupportQuantity ? "" : "hide")}>
              <label className="Field">
                <div className="Label">
                  <div className="Label-text">QTY</div>
                </div>
                <div>
                  <input
                    value={planQuantity}
                    onChange={e => onChangePlanQuantity(e.target.value)}
                    onBlur={e => onBlurPlanQuantity(e.target.value)}
                    className="Field-input"
                    type="text"
                  />
                </div>
              </label>
            </div>
          </div>
          )}
        <div className='Card-col u-textRight Flex-center Price-col'>
          <div className='price'>
            <span id='subscription_price'>
              <span className='Price'>${currencyFormatter.formatUSD(amount * planQuantity)} 
                <span className='Price-currency'>USD</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
)}

export default OrderSummaryPaidSubscription;
