import stepKeys from "../constants/stepKeys";

const freeSubscriptionSteps = [
    {
        stepKey: stepKeys.orderSummaryKey,
        stepNumber: 1,
        totalSteps: 4,
        stepTitle: "Base Subscription",
    },
    {
        stepKey: stepKeys.optionsKey,
        stepNumber: 2,
        totalSteps: 4,
        stepTitle: "Add-on Options",
    },
    {
        stepKey: stepKeys.contactInfoKey,
        stepNumber: 3,
        totalSteps: 4,
        stepTitle: "Contact Information",
    },
    {
        stepKey: stepKeys.billingInfoKey,
        stepNumber: 4,
        totalSteps: 4,
        stepTitle: "Billing Information",
    },
    {
        stepKey: stepKeys.finalizationKey,
        stepNumber: 5,
    },
];

const paidSubscriptionSteps = [
    {
        stepKey: stepKeys.orderSummaryKey,
        stepTitle: "Order Summary",
    },
    {
        stepKey: stepKeys.optionsKey,
        stepNumber: 1,
        totalSteps: 3,
        stepTitle: "Options",
    },
    {
        stepKey: stepKeys.contactInfoKey,
        stepNumber: 2,
        totalSteps: 3,
        stepTitle: "Contact Information",
    },
    {
        stepKey: stepKeys.billingInfoKey,
        stepNumber: 3,
        totalSteps: 3,
        stepTitle: "Billing Information",
    },
    {
        stepKey: stepKeys.finalizationKey,
        stepNumber: 4,
    },
];

const cvaSubscriptionSteps = [
    {
        stepKey: stepKeys.orderSummaryKey,
        stepTitle: "Order Summary",
    },
    {
        stepKey: stepKeys.optionsKey,
        stepNumber: 1,
        totalSteps: 3,
        stepTitle: "Total",
    },
    {
        stepKey: stepKeys.contactInfoKey,
        stepNumber: 2,
        totalSteps: 3,
        stepTitle: "Contact Information",
    },
    {
        stepKey: stepKeys.billingInfoKey,
        stepNumber: 3,
        totalSteps: 3,
        stepTitle: "Billing Information",
    },
    {
        stepKey: stepKeys.finalizationKey,
        stepNumber: 4,
    },
];

const freeCVASubscriptionSteps = [
    {
        stepKey: stepKeys.orderSummaryKey,
        stepNumber: 1,
        totalSteps: 4,
        stepTitle: "Base Subscription",
    },
    {
        stepKey: stepKeys.optionsKey,
        stepNumber: 2,
        totalSteps: 4,
        stepTitle: "Total",
    },
    {
        stepKey: stepKeys.contactInfoKey,
        stepNumber: 3,
        totalSteps: 4,
        stepTitle: "Contact Information",
    },
    {
        stepKey: stepKeys.billingInfoKey,
        stepNumber: 4,
        totalSteps: 4,
        stepTitle: "Billing Information",
    },
    {
        stepKey: stepKeys.finalizationKey,
        stepNumber: 5,
    },
];

const getSteps = (planCode) => {
    switch (planCode) {
        case "pva":
        case "pva-vhma": 
        case "ahi-annual":
            return freeSubscriptionSteps;
        case "cacva":
        case "cocva":
        case "decva":
        case "flcva":
        case "gacva":
        case "iacva":
        case "micva":
        case "ohcva":
            return cvaSubscriptionSteps;
        case "txcva":
            return freeCVASubscriptionSteps;
        default: 
            return paidSubscriptionSteps;
    }
};

const stepsFactory = {
    getSteps,
};

export default stepsFactory;