import CardHeader from './CardHeader';
import OptionList from './OptionList';

const Options = ({
  step,
  addOns = [],
  selectedAddOns = [],
  selectedAddOnQuantity = {},
  isCollapsed = false,
  isDisabled = false,
  changeHandler = () => { },
  onChangeSelectedAddonsQuantity = () => { },
  onBlurSelectedAddonsQuantity = () => { },
}) => {
  return (
    <div id='options-card' className={`Card Collapsible${isCollapsed ? ' collapsed' : ''}`} data-component='card'>
      <CardHeader 
        currentStep={step.stepNumber}
        stepsCount={step.totalSteps}
        cardTitle={step.stepTitle}
        displayStep={true}
      />
      <OptionList 
      addOns={addOns}
      selectedAddOns={selectedAddOns}
      selectedAddOnQuantity={selectedAddOnQuantity}
      isDisabled={isDisabled}
      changeHandler={changeHandler}
      onChangeSelectedAddonsQuantity={onChangeSelectedAddonsQuantity}
      onBlurSelectedAddonsQuantity={onBlurSelectedAddonsQuantity}
      />
    </div>
  );
}

export default Options;
