import CardHeader from "./CardHeader";

const ThankYou = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='Card'>
                    <CardHeader
                        cardTitle="Thank you!"
                        displayStep={false}
                    />
                    <div className='Card-row Card-row--soft'>
                        <div className='Card-col Flex-center Plan-col'>
                            Your payment was successful.
                            <span className='text-capitalize'>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;