import { useRef, useState } from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';

import CardHeader from './CardHeader';

const BillingInformation = ({
  step,
  isCollapsed = false,
  setIsLoading = () => {},
  onBack = () => {},
  onSubscribe = () => {}
}) => {
  const [country, setCountry] = useState('US')
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  const formRef = useRef();
  const recurly = useRecurly();

  function handleSubmit (event) {
    event.preventDefault()
    setIsError(false)

    if (!isChecked) {
      setIsError(true)
      return
    }

    setIsLoading(true)

    recurly.token(formRef.current, (err, token) => {
      if (err) {
        setIsError(true)
        setIsLoading(false)
        console.log(err)
        alert('Recurly service is currently unavailable. Please try again.')
      } else {
        onSubscribe(token)
      }
    });
  }
  
  return (
    <div
      className={`Card Collapsible${isCollapsed ? ' collapsed' : ''}`}
      id='billing-fields'
      style={{border: isError ? '1px solid red' : ''}}
    >
      <CardHeader 
        currentStep={step.stepNumber}
        stepsCount={step.totalSteps}
        cardTitle={step.stepTitle}
        displayStep={true}
      />
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className='Card-row Card-row--soft'>
          <div className='Card-col u-word-break'>
            <label className=' Field--required Field'>
              <div className='Label'>
                <div className='Label-text'>First Name</div>
              </div>
              <div>
                <input
                  maxLength='50'
                  className='Field-input'
                  size='30'
                  type='text'
                  name='account[first_name]'
                  id='account_first_name'
                  data-recurly="first_name"
                />
              </div>
            </label>
          </div>

          <div className='Card-col u-word-break'>
            <label className=' Field--required Field'>
              <div className='Label'>
                <div className='Label-text'>Last Name</div>
              </div>
              <div>
                <input
                  maxLength='50'
                  className='Field-input'
                  size='30'
                  type='text'
                  name='account[last_name]'
                  id='account_last_name'
                  data-recurly="last_name"
                />
              </div>
            </label>
          </div>
        </div>

        <div className='Card-row Card-row--soft'>
          <div className='Card-col u-word-break'>
            <CardElement />
          </div>
        </div>

        <div className='Card-row Card-row--soft'>
          <div className='Card-col u-word-break'>
            <label className='js-country-select-input Field Field--required'>
              <div className='Label'>
                <div className='Label-text'>Country</div>
              </div>
              <div>
                <div className='SelectInput'>
                  <select className='SelectInput-select' data-recurly='country' name='billing_info[country]' 
                    id='billing_info_country' defaultValue='US' onChange={e => setCountry(e.target.value)}>
                    <option value=''>Select...</option>
                    <option value='US'>United States</option>
                    <option></option>
                    <option value='AF'>Afghanistan</option>
                    <option value='AL'>Albania</option>
                    <option value='DZ'>Algeria</option>
                    <option value='AS'>American Samoa</option>
                    <option value='AD'>Andorra</option>
                    <option value='AO'>Angola</option>
                    <option value='AI'>Anguilla</option>
                    <option value='AQ'>Antarctica</option>
                    <option value='AG'>Antigua and Barbuda</option>
                    <option value='AR'>Argentina</option>
                    <option value='AM'>Armenia</option>
                    <option value='AW'>Aruba</option>
                    <option value='AU'>Australia</option>
                    <option value='AT'>Austria</option>
                    <option value='AZ'>Azerbaijan</option>
                    <option value='BS'>Bahamas</option>
                    <option value='BH'>Bahrain</option>
                    <option value='BD'>Bangladesh</option>
                    <option value='BB'>Barbados</option>
                    <option value='BY'>Belarus</option>
                    <option value='BE'>Belgium</option>
                    <option value='BZ'>Belize</option>
                    <option value='BJ'>Benin</option>
                    <option value='BM'>Bermuda</option>
                    <option value='BT'>Bhutan</option>
                    <option value='BO'>Bolivia</option>
                    <option value='BA'>Bosnia and Herzegovina</option>
                    <option value='BW'>Botswana</option>
                    <option value='BV'>Bouvet Island</option>
                    <option value='BR'>Brazil</option>
                    <option value='BQ'>British Antarctic Territory</option>
                    <option value='IO'>British Indian Ocean Territory</option>
                    <option value='VG'>British Virgin Islands</option>
                    <option value='BN'>Brunei</option>
                    <option value='BG'>Bulgaria</option>
                    <option value='BF'>Burkina Faso</option>
                    <option value='BI'>Burundi</option>
                    <option value='KH'>Cambodia</option>
                    <option value='CM'>Cameroon</option>
                    <option value='CA'>Canada</option>
                    <option value='CV'>Cape Verde</option>
                    <option value='KY'>Cayman Islands</option>
                    <option value='CF'>Central African Republic</option>
                    <option value='TD'>Chad</option>
                    <option value='CL'>Chile</option>
                    <option value='CN'>China</option>
                    <option value='CX'>Christmas Island</option>
                    <option value='CC'>Cocos [Keeling] Islands</option>
                    <option value='CO'>Colombia</option>
                    <option value='KM'>Comoros</option>
                    <option value='CD'>Congo [Democratic Republic]</option>
                    <option value='CG'>Congo [Republic]</option>
                    <option value='CK'>Cook Islands</option>
                    <option value='CR'>Costa Rica</option>
                    <option value='HR'>Croatia</option>
                    <option value='CU'>Cuba</option>
                    <option value='CW'>Curacao</option>
                    <option value='CY'>Cyprus</option>
                    <option value='CZ'>Czech Republic</option>
                    <option value='CI'>Côte d’Ivoire</option>
                    <option value='DK'>Denmark</option>
                    <option value='DJ'>Djibouti</option>
                    <option value='DM'>Dominica</option>
                    <option value='DO'>Dominican Republic</option>
                    <option value='EC'>Ecuador</option>
                    <option value='EG'>Egypt</option>
                    <option value='SV'>El Salvador</option>
                    <option value='GQ'>Equatorial Guinea</option>
                    <option value='ER'>Eritrea</option>
                    <option value='EE'>Estonia</option>
                    <option value='ET'>Ethiopia</option>
                    <option value='FK'>Falkland Islands [Malvinas]</option>
                    <option value='FO'>Faroe Islands</option>
                    <option value='FJ'>Fiji</option>
                    <option value='FI'>Finland</option>
                    <option value='FR'>France</option>
                    <option value='GF'>French Guiana</option>
                    <option value='PF'>French Polynesia</option>
                    <option value='TF'>French Southern Territories</option>
                    <option value='GA'>Gabon</option>
                    <option value='GM'>Gambia</option>
                    <option value='GE'>Georgia</option>
                    <option value='DE'>Germany</option>
                    <option value='GH'>Ghana</option>
                    <option value='GI'>Gibraltar</option>
                    <option value='GR'>Greece</option>
                    <option value='GL'>Greenland</option>
                    <option value='GD'>Grenada</option>
                    <option value='GP'>Guadeloupe</option>
                    <option value='GU'>Guam</option>
                    <option value='GT'>Guatemala</option>
                    <option value='GG'>Guernsey</option>
                    <option value='GN'>Guinea</option>
                    <option value='GW'>Guinea-Bissau</option>
                    <option value='GY'>Guyana</option>
                    <option value='HT'>Haiti</option>
                    <option value='HM'>Heard Island and McDonald Islands</option>
                    <option value='HN'>Honduras</option>
                    <option value='HK'>Hong Kong</option>
                    <option value='HU'>Hungary</option>
                    <option value='IS'>Iceland</option>
                    <option value='IN'>India</option>
                    <option value='ID'>Indonesia</option>
                    <option value='IR'>Iran</option>
                    <option value='IQ'>Iraq</option>
                    <option value='IE'>Ireland</option>
                    <option value='IM'>Isle of Man</option>
                    <option value='IL'>Israel</option>
                    <option value='IT'>Italy</option>
                    <option value='JM'>Jamaica</option>
                    <option value='JP'>Japan</option>
                    <option value='JE'>Jersey</option>
                    <option value='JO'>Jordan</option>
                    <option value='KZ'>Kazakhstan</option>
                    <option value='KE'>Kenya</option>
                    <option value='KI'>Kiribati</option>
                    <option value='KW'>Kuwait</option>
                    <option value='KG'>Kyrgyzstan</option>
                    <option value='LA'>Laos</option>
                    <option value='LV'>Latvia</option>
                    <option value='LB'>Lebanon</option>
                    <option value='LS'>Lesotho</option>
                    <option value='LR'>Liberia</option>
                    <option value='LY'>Libya</option>
                    <option value='LI'>Liechtenstein</option>
                    <option value='LT'>Lithuania</option>
                    <option value='LU'>Luxembourg</option>
                    <option value='MO'>Macao</option>
                    <option value='MG'>Madagascar</option>
                    <option value='MW'>Malawi</option>
                    <option value='MY'>Malaysia</option>
                    <option value='MV'>Maldives</option>
                    <option value='ML'>Mali</option>
                    <option value='MT'>Malta</option>
                    <option value='MH'>Marshall Islands</option>
                    <option value='MQ'>Martinique</option>
                    <option value='MR'>Mauritania</option>
                    <option value='MU'>Mauritius</option>
                    <option value='YT'>Mayotte</option>
                    <option value='MX'>Mexico</option>
                    <option value='FM'>Micronesia</option>
                    <option value='MD'>Moldova</option>
                    <option value='MC'>Monaco</option>
                    <option value='MN'>Mongolia</option>
                    <option value='ME'>Montenegro</option>
                    <option value='MS'>Montserrat</option>
                    <option value='MA'>Morocco</option>
                    <option value='MZ'>Mozambique</option>
                    <option value='MM'>Myanmar [Burma]</option>
                    <option value='NA'>Namibia</option>
                    <option value='NR'>Nauru</option>
                    <option value='NP'>Nepal</option>
                    <option value='NL'>Netherlands</option>
                    <option value='NC'>New Caledonia</option>
                    <option value='NZ'>New Zealand</option>
                    <option value='NI'>Nicaragua</option>
                    <option value='NE'>Niger</option>
                    <option value='NG'>Nigeria</option>
                    <option value='NU'>Niue</option>
                    <option value='NF'>Norfolk Island</option>
                    <option value='KP'>North Korea</option>
                    <option value='MK'>North Macedonia</option>
                    <option value='XI'>Northern Ireland</option>
                    <option value='MP'>Northern Mariana Islands</option>
                    <option value='NO'>Norway</option>
                    <option value='OM'>Oman</option>
                    <option value='PK'>Pakistan</option>
                    <option value='PW'>Palau</option>
                    <option value='PS'>Palestinian Territories</option>
                    <option value='PA'>Panama</option>
                    <option value='PG'>Papua New Guinea</option>
                    <option value='PY'>Paraguay</option>
                    <option value='PE'>Peru</option>
                    <option value='PH'>Philippines</option>
                    <option value='PN'>Pitcairn Islands</option>
                    <option value='PL'>Poland</option>
                    <option value='PT'>Portugal</option>
                    <option value='PR'>Puerto Rico</option>
                    <option value='QA'>Qatar</option>
                    <option value='RO'>Romania</option>
                    <option value='RU'>Russia</option>
                    <option value='RW'>Rwanda</option>
                    <option value='RE'>Réunion</option>
                    <option value='BL'>Saint Barthélemy</option>
                    <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                    <option value='KN'>Saint Kitts and Nevis</option>
                    <option value='LC'>Saint Lucia</option>
                    <option value='MF'>Saint Martin</option>
                    <option value='PM'>Saint Pierre and Miquelon</option>
                    <option value='VC'>Saint Vincent and the Grenadines</option>
                    <option value='WS'>Samoa</option>
                    <option value='SM'>San Marino</option>
                    <option value='SA'>Saudi Arabia</option>
                    <option value='SN'>Senegal</option>
                    <option value='RS'>Serbia</option>
                    <option value='SC'>Seychelles</option>
                    <option value='SL'>Sierra Leone</option>
                    <option value='SG'>Singapore</option>
                    <option value='SX'>Sint Maarten [Dutch part]</option>
                    <option value='SK'>Slovakia</option>
                    <option value='SI'>Slovenia</option>
                    <option value='SB'>Solomon Islands</option>
                    <option value='SO'>Somalia</option>
                    <option value='ZA'>South Africa</option>
                    <option value='GS'>South Georgia and the South Sandwich Islands</option>
                    <option value='KR'>South Korea</option>
                    <option value='SS'>South Sudan</option>
                    <option value='ES'>Spain</option>
                    <option value='LK'>Sri Lanka</option>
                    <option value='SD'>Sudan</option>
                    <option value='SR'>Suriname</option>
                    <option value='SJ'>Svalbard and Jan Mayen</option>
                    <option value='SZ'>Swaziland</option>
                    <option value='SE'>Sweden</option>
                    <option value='CH'>Switzerland</option>
                    <option value='SY'>Syria</option>
                    <option value='ST'>São Tomé and Príncipe</option>
                    <option value='TW'>Taiwan</option>
                    <option value='TJ'>Tajikistan</option>
                    <option value='TZ'>Tanzania</option>
                    <option value='TH'>Thailand</option>
                    <option value='TL'>Timor-Leste</option>
                    <option value='TG'>Togo</option>
                    <option value='TK'>Tokelau</option>
                    <option value='TO'>Tonga</option>
                    <option value='TT'>Trinidad and Tobago</option>
                    <option value='TN'>Tunisia</option>
                    <option value='TR'>Turkey</option>
                    <option value='TM'>Turkmenistan</option>
                    <option value='TC'>Turks and Caicos Islands</option>
                    <option value='TV'>Tuvalu</option>
                    <option value='UM'>U.S. Minor Outlying Islands</option>
                    <option value='VI'>U.S. Virgin Islands</option>
                    <option value='UG'>Uganda</option>
                    <option value='UA'>Ukraine</option>
                    <option value='AE'>United Arab Emirates</option>
                    <option value='GB'>United Kingdom</option>
                    <option value='US'>United States</option>
                    <option value='UY'>Uruguay</option>
                    <option value='UZ'>Uzbekistan</option>
                    <option value='VU'>Vanuatu</option>
                    <option value='VA'>Vatican City</option>
                    <option value='VE'>Venezuela</option>
                    <option value='VN'>Vietnam</option>
                    <option value='WF'>Wallis and Futuna</option>
                    <option value='EH'>Western Sahara</option>
                    <option value='YE'>Yemen</option>
                    <option value='ZM'>Zambia</option>
                    <option value='ZW'>Zimbabwe</option>
                    <option value='AX'>Åland Islands</option>
                  </select>
                  <div className='SelectInput-arrows'></div>
                </div>
              </div>
            </label>
          </div>

          <div className='Card-col u-word-break'>
            <label className=' Field--required Field'>
              <div className='Label'>
                <div className='Label-text'>Street Address</div>
              </div>
              <div>
                <input
                  data-recurly='address1'
                  maxLength='50'
                  className='Field-input'
                  size='30'
                  type='text'
                  name='billing_info[address1]'
                  id='billing_info_address1'
                />
              </div>
            </label>
          </div>
        </div>

        <div className='Card-row Card-row--soft'>
          <div className='Card-col u-word-break'>
            <label className=' Field--required Field'>
              <div className='Label'>
                <div className='Label-text'>City</div>
              </div>
              <div>
                <input
                  data-recurly='city'
                  maxLength='50'
                  className='Field-input'
                  size='30'
                  type='text'
                  name='billing_info[city]'
                  id='billing_info_city'
                />
              </div>
            </label>
          </div>

          <div className='Card-col u-word-break'>
            <label className={'Field' + (country === 'US' ? ' Field--required' : '')}>
              <div className='Label'>
                <div className='Label-text'>State</div>
              </div>
              <div>
                {
                  country === 'US'
                    ? (
                      <div className='SelectInput'>
                        <select
                          className='SelectInput-select'
                          name='billing_info[state]'
                          id='billing_info_state'
                          data-recurly="state"
                        >
                          <option value=''>State</option>
                          <option value='AL'>Alabama</option>
                          <option value='AK'>Alaska</option>
                          <option value='AS'>America Samoa</option>
                          <option value='AZ'>Arizona</option>
                          <option value='AR'>Arkansas</option>
                          <option value='AA'>Armed Forces Americas</option>
                          <option value='AE'>Armed Forces</option>
                          <option value='AP'>Armed Forces Pacific</option>
                          <option value='CA'>California</option>
                          <option value='CO'>Colorado</option>
                          <option value='CT'>Connecticut</option>
                          <option value='DE'>Delaware</option>
                          <option value='DC'>District of Columbia</option>
                          <option value='FM'>Federated States of Micronesia</option>
                          <option value='FL'>Florida</option>
                          <option value='GA'>Georgia</option>
                          <option value='GU'>Guam</option>
                          <option value='HI'>Hawaii</option>
                          <option value='ID'>Idaho</option>
                          <option value='IL'>Illinois</option>
                          <option value='IN'>Indiana</option>
                          <option value='IA'>Iowa</option>
                          <option value='KS'>Kansas</option>
                          <option value='KY'>Kentucky</option>
                          <option value='LA'>Louisiana</option>
                          <option value='ME'>Maine</option>
                          <option value='MH'>Marshall Islands</option>
                          <option value='MD'>Maryland</option>
                          <option value='MA'>Massachusetts</option>
                          <option value='MI'>Michigan</option>
                          <option value='MN'>Minnesota</option>
                          <option value='MS'>Mississippi</option>
                          <option value='MO'>Missouri</option>
                          <option value='MT'>Montana</option>
                          <option value='NE'>Nebraska</option>
                          <option value='NV'>Nevada</option>
                          <option value='NH'>New Hampshire</option>
                          <option value='NJ'>New Jersey</option>
                          <option value='NM'>New Mexico</option>
                          <option value='NY'>New York</option>
                          <option value='NC'>North Carolina</option>
                          <option value='ND'>North Dakota</option>
                          <option value='MP'>Northern Mariana Islands</option>
                          <option value='OH'>Ohio</option>
                          <option value='OK'>Oklahoma</option>
                          <option value='OR'>Oregon</option>
                          <option value='PA'>Pennsylvania</option>
                          <option value='PR'>Puerto Rico</option>
                          <option value='PW'>Palau</option>
                          <option value='RI'>Rhode Island</option>
                          <option value='SC'>South Carolina</option>
                          <option value='SD'>South Dakota</option>
                          <option value='TN'>Tennessee</option>
                          <option value='TX'>Texas</option>
                          <option value='UT'>Utah</option>
                          <option value='VA'>Virginia</option>
                          <option value='VI'>Virgin Islands</option>
                          <option value='VT'>Vermont</option>
                          <option value='WA'>Washington</option>
                          <option value='WI'>Wisconsin</option>
                          <option value='WV'>West Virginia</option>
                          <option value='WY'>Wyoming</option>
                        </select>
                        <div className='SelectInput-arrows'></div>
                      </div>
                    )
                    : (
                      <input
                        data-recurly='state'
                        maxLength='50'
                        className='Field-input'
                        size='30'
                        type='text'
                        name='billing_info[state]'
                        id='billing_info_state'
                      />
                    )
                }
              </div>
            </label>
          </div>

          <div className='Card-col u-word-break'>
            <label className='zip  Field--required Field'>
              <div className='Label'>
                <div className='Label-text'>Zip/Postal</div>
              </div>
              <div>
                <input
                  data-recurly='postal_code'
                  maxLength='20'
                  className='Field-input'
                  size='30'
                  type='text'
                  name='billing_info[zip]'
                  id='billing_info_zip'
                />
              </div>
            </label>
          </div>
        </div>

        <div className='Card-row Card-row--soft'>
          <div className='Card-col u-word-break'>
            <a
              className='Button Button--primary Button--solid Button-back'
              id='billing-button-back'
              onClick={onBack}
            >
              Back
            </a>
            <button
              className='Button Button--primary Button--solid'
              id='billing-button'
              type='submit'
            >
              Subscribe
            </button>
          </div>
        </div>

        <div id='terms-acceptance' className='Card-row'>
          <div className='Card-col u-word-break'>
            <div className='Field' id='terms-checkbox'>
              <div className='Label'>
                <div className='Label-text'>Terms and Privacy</div>
              </div>
              <label className='Checkbox'>
                <input
                  type='checkbox'
                  name='accept'
                  id='accept'
                  data-recurly='accept'
                  className='Checkbox-input'
                  checked={isChecked}
                  onChange={() => setIsChecked(isChecked => !isChecked)}
                />
                <div className='Checkbox-label'>
                  I accept the <a target='_blank' rel='nofollow noopener noreferrer' href='http://stafftraining.4act.com/terms-of-service/'>Terms of Service</a>.
                </div>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BillingInformation;
