const CardHeader = ({
    currentStep, 
    stepsCount, 
    cardTitle,
    displayStep = false,
    isRequiredCard = false,
}) => {
    return (
        <>
            {displayStep && <div className='Card-steps'>
                <p>
                    Step {currentStep} of {stepsCount}
                </p>
            </div>}
            <div className={displayStep ? 'Card-head__with-step' : 'Card-head'}>
                <div className='Titlebar' data-component='titlebar'>
                    <h3 className={isRequiredCard ? 'Card-head__title-required' : ''}>{cardTitle}</h3>
                </div>
            </div>
        </>
    );
}

export default CardHeader;