const orderSummaryKey = "orderSummary";
const optionsKey = "options";
const contactInfoKey = "contactInfo";
const billingInfoKey = "billingInfo";
const finalizationKey = "finalization";

const stepKeys = {
    orderSummaryKey,
    optionsKey,
    contactInfoKey,
    billingInfoKey,
    finalizationKey,
};

export default stepKeys;