import { useRef } from "react";

import StepsStateMachine from "../model/stateMachines/StepsStateMachine";

function useStepsStateMachine(steps) {
    const stepsStateMachineRef = useRef();
    if (!stepsStateMachineRef.current) {
        stepsStateMachineRef.current = new StepsStateMachine(steps);
    }
    return stepsStateMachineRef.current;
}

export default useStepsStateMachine;