import CardHeader from "./CardHeader";

const ContactInformation = ({
  step,
  firstName = '',
  lastName = '',
  email = '',
  phoneNumber = '',
  company = '',
  salesRepresentative = '',
  isCollapsed = false,
  changeHandler = () => {},
  onBack = () => {},
  onDone = () => {}
}) => {
  return (
  <>
    <div className={`Card Collapsible${isCollapsed ? ' collapsed' : ''}`} id='contact-fields'>
      <CardHeader 
        currentStep={step.stepNumber}
        stepsCount={step.totalSteps}
        cardTitle={step.stepTitle}
        displayStep={true}
      />
      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
          <label className='Field--required Field'>
            <div className='Label'>
              <div className='Label-text'>First Name</div>
            </div>
            <div>
              <input
                maxLength='50'
                className='Field-input'
                size='30'
                type='text'
                name='account[first_name]'
                id='account_first_name'
                value={firstName}
                onChange={e => changeHandler('firstName', e.target.value)}
              />
            </div>
          </label>
        </div>

        <div className='Card-col u-word-break'>
          <label className=' Field--required Field'>
            <div className='Label'>
              <div className='Label-text'>Last Name</div>
            </div>
            <div>
              <input
                maxLength='50'
                className='Field-input'
                size='30'
                type='text'
                name='account[last_name]'
                id='account_last_name'
                value={lastName}
                onChange={e => changeHandler('lastName', e.target.value)}
              />
            </div>
          </label>
        </div>
      </div>

      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
          <label className=' Field--required Field'>
            <div className='Label'>
              <div className='Label-text'>Email</div>
            </div>
            <div>
              <input
                type='email'
                maxLength='250'
                className='Field-input'
                size='30'
                name='account[email]'
                id='account_email'
                value={email}
                onChange={e => changeHandler('email', e.target.value)}
              />
            </div>
          </label>
        </div>
      </div>

      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
          <label className='Field'>
            <div className='Label'>
              <div className='Label-text'>Phone Number</div>
            </div>
            <div>
              <input
                type='tel'
                maxLength='30'
                className='Field-input'
                size='30'
                name='account[address][phone]'
                id='account_address_phone'
                value={phoneNumber}
                onChange={e => changeHandler('phoneNumber', e.target.value)}
              />
            </div>
          </label>
        </div>

        <div className='Card-col u-word-break'>
          <label className=' Field--required Field'>
            <div className='Label'>
              <div className='Label-text'>Company / Organization Name</div>
            </div>
            <div>
              <input
                maxLength='100'
                className='Field-input'
                size='30'
                type='text'
                name='account[company_name]'
                id='account_company_name'
                value={company}
                onChange={e => changeHandler('company', e.target.value)}
              />
            </div>
          </label>
        </div>
      </div>

      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
          <label className='Field'>
            <div className='Label'>
              <div className='Label-text'>Name of the Patterson, PVA or AHI Representative you worked with</div>
            </div>
            <div>
              <input
                maxLength='100'
                className='Field-input'
                size='30'
                type='text'
                name='account[sales_representative]'
                id='account_sales_representative'
                value={salesRepresentative}
                onChange={e => changeHandler('salesRepresentative', e.target.value)}
              />
            </div>
          </label>
        </div>
      </div>

      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
        <button
            className='Button Button--primary Button--solid Button-back'
            id='contact-button-back'
            tabIndex='0'
            onClick={onBack}
          >
            Back
          </button>
          <button
            className='Button Button--primary Button--solid'
            id='contact-button'
            tabIndex='0'
            onClick={onDone}
          >
            Continue
          </button>
        </div>
      </div>
    </div>

    <div className={`Card ${isCollapsed ? '' : ' hide'}`} id='contact-fields-text'>
      <div className='Card-row Card-row--soft'>
        <div className='Card-col u-word-break'>
          <p>
            <span className='contact-firstname' data-text='account_first_name'>{firstName}</span>
            <span className='contact-lastname' data-text='account_last_name'>{lastName}</span>
          </p>
          <p className='contact-email' data-text='account_email'>{email}</p>
          <p className='contact-phone' data-text='account_address_phone'>{phoneNumber}</p>
          <p className='contact-business' data-text='account_company_name'>{company}</p>
        </div>
      </div>
    </div>
  </>
)};

export default ContactInformation;
