import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import constants from './model/constants/constants';


const Coupon = ({
  isCollapsed = true,
  onApply = () => {},
  onRemove = () => {},
  setIsLoading = () => {}
}) => {
  const [coupon, setCoupon] = useState('')
  const [isApplied, setIsApplied] = useState(false)

  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const handleApply = () => {
    setIsLoading(true)
    fetch(`${constants.URL}${code}/coupons/${coupon}`).then(
      r => {
        if (!r.ok) {
          return Promise.reject(r)
        }
        return r.json()
      }
    ).then(
      r => {
        onApply({...r, coupon})
        setIsApplied(true)
      }
    ).catch(
      console.error
    ).finally(
      () => setIsLoading(false)
    )
  }

  const handleRemove = () => {
    setCoupon('')
    setIsApplied(false)
    onRemove()
  }

  if (isCollapsed) return <></>;
  
  return (
    <div id='order_summary_w'>
      <div id='coupon_w'>
        <div className='Card' data-component='card'>
          <div className='Card-head'>
            <div className='Titlebar' data-component='titlebar'>
              <h3>Coupon Code</h3>
            </div>
          </div>
          <div className='Card-row'>
            <div id='coupon-code-input-column' className='u-bottom-padding-24 Card-col u-word-break'>
              <label className={`Field${isApplied ? ' hide' : ''}`}>
                <div className='Label'>
                  <div className='Label-text'>Coupon Code</div>
                </div>
                <div>
                  <div className='Search'>
                    <input
                      type='input'
                      className='Search-input Field-input'
                      name='subscription[coupon_code]'
                      id='subscription_coupon_code'
                      value={coupon}
                      onChange={e => setCoupon(e.target.value)}
                    />
                    <button
                      name='check_coupon'
                      type='button'
                      className='Search-button'
                      id='check_coupon'
                      onClick={handleApply}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </label>
              <p className={`Paragraph${isApplied ? '' : ' hide'}`} id='coupon-applied'>
                <span className='bold coupon-code-applied'>{coupon}</span>
                <span className='code-input-field' id='coupon_status'></span>
                <span></span>
                <button
                  name='check_coupon'
                  type='button'
                  className='Search-button'
                  id='check_coupon'
                  onClick={handleRemove}
                >
                  Remove
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='Col' id='coupon-giftcard-input-spacer'></div>
    </div>
  )
}

export default Coupon;
