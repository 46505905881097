import logoFactory from "./model/factories/logoFactory"

const Header = ({ code = "" }) => {
  return (
    <div id='header'>
      <div className='container'>
        <div className='row logo-wrap'>
          <div className='col-md-8 col-lg-10'>
            <div id='logo'>
              <img
                alt='logo'
                className='logo'
                src={logoFactory.getLogo(code)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
