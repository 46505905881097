const formatUSD = amount => {
  amount = `${amount}`
  if (amount.length > 2) {
    const before = amount.substring(0, amount.length - 2)
    const after = amount.substring(amount.length - 2)
    amount = `${before}.${after}`
  }
  return amount
}

const currencyFormatter = {
  formatUSD
}

export default currencyFormatter
