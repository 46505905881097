const stepsNullObject = [];

class StepsStateMachine {
    constructor(steps) {
        this.steps = steps || stepsNullObject;
        this.currentStep = this.steps.find(step => step.stepNumber === 1);
    }

    getCurrentStep() {
        return this.currentStep;
    }

    getStepByKey(key) {
        return this.steps.find(step => step.stepKey === key);
    }

    goToNextStep() {
        const nextStep = this.steps.find(step => step.stepNumber === this.currentStep.stepNumber + 1);

        if (nextStep) this.currentStep = nextStep;
    }

    goToPrevStep() {
        const prevStep = this.steps.find(step => step.stepNumber === this.currentStep.stepNumber - 1);

        if (prevStep) this.currentStep = prevStep;
    }
};

export default StepsStateMachine;