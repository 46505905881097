import currencyFormatter from './model/formatters/currencyFormatter';

const OptionList = ({
    addOns = [],
    selectedAddOns = [],
    selectedAddOnQuantity = {},
    isDisabled = false,
    changeHandler = () => { },
    onChangeSelectedAddonsQuantity = () => { },
    onBlurSelectedAddonsQuantity = () => { },
  }) => (
    <div id='subscription_add_ons'>
      {
        addOns.map(addOn => {
          const isSupportQuantity = Object.keys(selectedAddOnQuantity).includes(addOn.code)
          const coef = isSupportQuantity
            ? parseInt(selectedAddOnQuantity[addOn.code]) || 0
            : 1
          return (
            <div className='Card-row subscription_add_on' key={addOn.code}>
              <div className='Card-col Card-col--soft Flex-center'>
                <input
                  className='Checkbox-input subscription_add_on_check'
                  type='checkbox'
                  disabled={isDisabled}
                  checked={selectedAddOns.includes(addOn.code)}
                  onChange={() => changeHandler(addOn.code)}
                  name='subscription[subscription_add_ons_attributes][0][_new]'
                  id='subscription_subscription_add_ons_attributes_0__new'
                />
                <div className='Checkbox-label'>
                  {addOn.name}
                </div>
              </div>
              {isSupportQuantity && (
                <div className="Card-col Card-col--soft Qty-col Flex-center">
                  <div className={"qty qty-editable" + " " + (selectedAddOns.includes(addOn.code) ? "" : "hide")}>
                    <label className="Field">
                      <div className="Label">
                        <div className="Label-text">QTY</div>
                      </div>
                      <div>
                        <input
                          value={selectedAddOnQuantity[addOn.code]}
                          onChange={e => onChangeSelectedAddonsQuantity(addOn.code, e.target.value)}
                          onBlur={() => onBlurSelectedAddonsQuantity(addOn.code)}
                          className="Field-input"
                          type="text"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              )}
              <div className='Card-col Card-col--soft Price-col Flex-center'>
                <span className='subscription_add_on_price price price-disabled' data-amount_in_cents='29000'>
                  <span className='Price'>${currencyFormatter.formatUSD(addOn.amount * coef)}
                    <span className='Price-currency'>USD</span>
                  </span>
                </span>
              </div>
            </div>
          )
        })
      }
    </div>
  )

  export default OptionList;