import { createPortal } from 'react-dom';

const LoaderComponent = () => (
  <div className='loader-component'>
    <div className='loader-component__loader'></div>
  </div>
);

const Loader = ({ isOpen = false }) => {
  if (!isOpen) {
    return <></>;
  }

  return createPortal(
    <LoaderComponent />,
    document.querySelector('#loader-root')
  );
};

export default Loader;
